export class Prize {
    _id = '';
    name = '';
    vendor = new Vendor();
    initialQuantity = 0;
    remainingQuantity = 0;
    dailyQuota = 0;
    promotion = '';
    type = '';
    simCardPrice = 0;
    addOnCode = '';
    validity = 0;
    giftLevel = 0;
    dailyQuantity = 0;
    prize = '';
    usefor = '';
    isPhysicalPrize = false;
}

export class NewPrize {
    constructor(obj: Prize) {
        this.name = obj.name;
        this.vendor = obj.vendor._id;
        this.initialQuantity = obj.initialQuantity;
        this.remainingQuantity = obj.remainingQuantity;
        this.dailyQuota = obj.dailyQuota;
        this.promotion = obj.promotion;
        this.type = obj.type;
        this.simCardPrice = obj.simCardPrice;
        this.addOnCode = obj.addOnCode;
        this.validity = obj.validity;
        this.giftLevel = obj.giftLevel;
        this.dailyQuantity = obj.dailyQuantity;
        this.prize = obj.prize;
        this.usefor = obj.usefor;
        this.isPhysicalPrize = obj.isPhysicalPrize;
    }
    name = '';
    vendor = '';
    initialQuantity = 0;
    remainingQuantity = 0;
    dailyQuota = 0;
    promotion = '';
    type = '';
    simCardPrice = 0;
    addOnCode = '';
    validity = 0;
    giftLevel = 1;
    dailyQuantity = 0;
    prize = '';
    usefor = '';
    isPhysicalPrize = false;
}

export class Vendor {
    _id = '';
    code = '';
    name = '';
}
export class CashRushPrize {
    _id = '';
    name = '';
    promotion = '';
    giftLevel = 1;
    dailyQuantity = 0;
    prize = '';
}
export class CashRushPrizeNew {
    constructor(obj: CashRushPrize) {
        this.name = obj.name;
        this.promotion = obj.promotion;
        this.giftLevel = obj.giftLevel;
        this.dailyQuantity = obj.dailyQuantity;
        this.prize = obj.prize;
    }
    name = '';
    promotion = '';
    giftLevel = 1;
    dailyQuantity = 0;
    prize = '';
}
