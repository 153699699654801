import { Time } from '@shared/classes';
import * as moment from 'moment';

export const startOfMonth = (date: Date) =>
    moment(date)
        .startOf('month')
        .toDate();

export const endOfMonth = (date: Date) =>
    moment(date)
        .endOf('month')
        .toDate();

export const Today = (date: Date) =>
    moment(date)
        .startOf('day')
        .toDate();

export const ConcatDateTime = (date: Date, time: Time) => {
    return moment(date)
        .add(time.hour, 'hours')
        .add(time.minute, 'minutes')
        .toISOString();
};
